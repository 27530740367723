.image-swiper .swiper-wrapper {
  display: flex;
  align-items: center !important;
}

.image-swiper .swiper-slide img {
  width: 100%;
  height: 180px;
}

@media screen and (max-width: 1200px) {
  .image-swiper .swiper-slide img {
    height: 200px;
  }
}

.image-swiper .swiper {
  overflow: hidden;
  position: relative;
  --v-offset: 60px;
  --curve-height: 120px;
}

.image-swiper .swiper::before,
.swiper::after {
  content: "";
  z-index: 99;
  display: block;
  background: white;
  width: calc(100vw + 2 * var(--v-offset));
  height: var(--curve-height);
  position: absolute;
  border-radius: 45%;
  left: calc(-1 * var(--v-offset));
  right: calc(-1 * var(--v-offset));
}

.image-swiper .swiper::before {
  top: calc(-0.7 * var(--curve-height));
}

.image-swiper .swiper::after {
  bottom: calc(-0.7 * var(--curve-height));
}

.wrapper {
  display: grid;
  grid-template-rows: 300px;
  grid-auto-flow: column;
  grid-gap: 24px;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.wrapper img {
  scroll-snap-align: center;
}
